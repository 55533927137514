import { DIGITS } from "./constants";

export const filterNonNull = (array) => {
    return array.filter((x) => x !== null);
  };

  export function toGermanDate(date) {

    date = date.toString().split('T');
    date = date[0].split('-');
    date = date[2] + '.' + date[1] + '.' + date[0];
    return date

}

//import {CURRENCY as NEWCURRENCY} from './components/Constants';

export function toGermanFloat(number) {
  // if (NEWCURRENCY === 'CHF') return number;

   if(number){
   var negative = "";
   if (number?.includes('-')) {
       number=number.replace('-', '')
       negative = "-";
   }
   number = number?.toString().split('.');
   number[0] = '' + number[0];
   if (number[0].length > 3) {
       var mod = number[0].length % 3;
       var output = (mod > 0 ? (number[0].substring(0, mod)) : '');
       for (var i = 0; i < Math.floor(number[0].length / 3); i++) {
           if ((mod === 0) && (i === 0))
               output += number[0].substring(mod + 3 * i, mod + 3 * i + 3);
           else
           // hier wird das Trennzeichen festgelegt mit '.'
           { output += '.' + number[0].substring(mod + 3 * i, mod + 3 * i + 3); }
       }
       return (output = negative + output + ',' + number[1]);
   }
   else return number[0] = negative + number[0] + ',' + number[1];
}
else return "";

}



export const roundDigits = (number)=> {
  var factor = '1';
  while (factor.length <= DIGITS) { factor = factor + '0'; }
  number = Math.round(number * factor) / factor;
  number = number?.toString().split('.');
  if (number[1] === undefined) number[1] = '0';
  while (number[1]?.length < DIGITS) { number[1] = number[1] + '0'; }

  return toGermanFloat(number[0] + '.' + number[1])
}


const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
