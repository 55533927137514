import React, { useState } from "react";
import { ReactComponent as BarsIcon } from '../assets/pic/bars-solid.svg';
import logo from '../assets/pic/appLogo.png';
import { ReactComponent as SearchIcon } from '../assets/pic/search.svg';
import LanguageSelector from '../languages/LanguageSelector';
import "../style/styles.css";
import "../style/cart.css";
import Order from "../shop/Order";
import Bill from "../shop/Bill";
//import useToken from './useToken';
import { Text } from '../languages/Language';
import UserProfile from "./UserProfile";
import LoginModal from "../shop/LoginModal";
import RegisterModal from "../shop/RegisterModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketball, faBasketShopping, faClose, faHome, faSearch, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';


export default function Menu(props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [term, setTerm] = useState('');
  const [cartQuantity, setCartQuantity] = useState(0);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const handleShowRegisterModal = (value) => {
    setShowRegisterModal(value);
  };

  const addToCart = () => {
    setCartQuantity(cartQuantity + 1);
  };

  const handleInputChange = (event) => {
    setTerm(event.target.value);
    props.search(event.target.value);
    if(event.target.value==""){
      handleOpen();
    }
  };

  const clickHandler = () => {
    console.log(menuOpen)
    setMenuOpen(!menuOpen)
    props.search("");
    setTerm("")
    //setMenuOpen(!menuOpen)
  }

  const parentHandleToken = (isLogged) => {

    props.setAppToken(isLogged);
  };

  const handleOpen = () => {
    props.WebCategory("Home", null)
  };

  return (
    <>
      <div
        className="font-normal text-white w-full flex flex-row bg-menu-secondary-color"
      >
        <nav
          className=" w-full  lg:px-3 items-center justify-between flex flex-row"
        >

          <div
            className="px-4 md:hidden flex-row  justify-start flex"
          >
            <button
              className="lg:hidden cursor-pointer text-xl leading-none p-1
                border border-solid border-transparent rounded-lg bg-transparent
                outline-none focus:outline-none"
              type="button"
              onClick={props.toggleMenu}
            >
              <BarsIcon
                className="px-2 w-10 fill-white"
              />
            </button>
            <button
              className="cursor-pointer text-xl leading-none border border-solid
                  border-transparent rounded-lg bg-transparent outline-none focus:outline-none"
              type="button"
              onClick={props.onClick}
            >
            </button>
          </div>

          <div className={`${menuOpen?"hidden":"grid flex-none items-center content-center w-28 sm:w-32 m-1 h-5/6 lg:w-32 "}`}
              >
            <img className="flex w-24 items-center justify-center lg:w-24 p-1" src={logo} alt="Logo" />
          </div>
          <button
            title="Home"
                  onClick={handleOpen}
                  className="hidden md:flex m-1 items-center
              justify-center  p-4 ml-auto
              text-sm font-bold text-white  hover:opacity-75"
                  href="#pablo"
                >
                  <FontAwesomeIcon className=" h-5" icon={faHome}/>
                  <div className="pl-2 hidden lg:block text-sm"><Text tid="Home"/></div>
                </button>

          <div className="flex mr-auto">
          { /*desktop search */}
            {sessionStorage.getItem("token")
              ?
              <>


                <div
                  className="hidden md:flex items-center w-full "
                >
                  <div className="flex flex-row items-center gap-x-1 bg-white pl-2 rounded-sm">
                  <div>
              <FontAwesomeIcon color="gray" icon={faSearch}/>
                    </div>
                    <div>
                    <input
                      value={term} onChange={(event) => { handleInputChange(event) }}
                      type="search"
                      className="appearance-none form-control  flex w-[30vw]  h-10
                  text-base font-normal text-slate-700
                 border-2 border-white transition ease-in-out rounded-sm
                   m-0 focus:text-slate-700 focus:bg-white  focus:ring-0 focus:border-white
                   focus:outline-none"
                      aria-label="Search"
                      aria-describedby="button-addon3"
                    />
                    </div>

                  </div>


                  </div>
                </>

              : <div></div>
            }
          </div>

          <div
            className="flex font-bold flex-row px-5 py-1 justify-end "
          >



            <ul
              className="items-center xl:space-x-2 flex justify-end flex-row  xl:w-1/3
             lg:flex-row list-none"
            >

              { /*bill order */}
              {sessionStorage.getItem("token")
                ? <>
                  <li
                    className="nav-item hidden md:flex"
                  >
                    <Order onWait={props.onWait} onLoadSavedSlip={() => props.onLoadSavedSlip()} />
                  </li>
                  <li
                    className="nav-item hidden md:flex"
                  >
                    <Bill />
                  </li>   </>
                : null
              }

              { /*login register language */}
              {!sessionStorage.getItem("token")
                ? <>
                <li>
                  <RegisterModal showModal={showRegisterModal} setShowModal={handleShowRegisterModal}></RegisterModal>
                </li>

                  <li
                    className="nav-item"
                  >
                    <LoginModal

                    parentHandleToken={parentHandleToken}
                    onGetItems={() => props.onGetItems()}
                    onGetCategories={() => props.onGetCategories()}
                    setShowRegisterModal={handleShowRegisterModal}
                     />
                  </li>
                  <li>
                    <LanguageSelector menu="true" />
                  </li>
                </>
                : null
              }

              { /*mobile search input */}
                <div
                  className={`${menuOpen?"block md:hidden justify-center flex-row items-center ":"hidden"}`}
                >

                    <input
                      value={term} onChange={(event) => { handleInputChange(event) }}
                      type="search"
                      className=" appearance-none form-control relative flex   px-1 h-10
                      py-1.5 text-base font-normal text-slate-700 bg-white bg-clip-padding
                       border-white transition ease-in-out rounded-sm
                      m-0 focus:text-slate-700 focus:bg-white  focus:ring-0 focus:border-white
                      focus:outline-none"
                      placeholder=""
                      aria-label="Search"
                      aria-describedby="button-addon3"
                    />

                  </div>

              { /*mobile search button */}
                  <button
                      className="block md:hidden appearance-none px-4 py-2  text-white
                   font-medium text-xs leading-tight
                    hover:outline-none focus:outline-none focus:ring-0 focus:ring-transparent transition
                    flex items-center justify-end
                    duration-150 ease-in-out"
                      type="button"
                      id="button-addon3"
                      onClick={clickHandler}
                    >
                      {menuOpen?
                      <FontAwesomeIcon className="w-6 h-6 fill-white" icon={faClose}/> :
                      <SearchIcon className="w-6 h-6 fill-white" />}
                    </button>

              { /* cart */}
              <div onClick={props.toggleCart}
              className={`${menuOpen?
                "hidden":
                "relative self-center text-white cursor-pointer text-xxs lg:text-sm"
                }`}>

                <div className='flex flex-row h-4 items-center relative hover:opacity-75 px-1 ml-0 sm:ml-1 '>
                   <FontAwesomeIcon icon={faBasketShopping} className="h-6 w-6 fill-white stroke-white "/>
                    {props.cartCount > 0 && (
                      <span className="cart-badge">{props.cartCount}</span>
                     )}
                     <div className="text-smfont-bold pl-1.5 hidden lg:block"><Text tid="ShoppingCart"></Text></div>
                 </div>
              </div>




              {sessionStorage.getItem('user')&&!menuOpen
                ?
                <li>
                  <UserProfile
                  ShowImpressum={(value) => props.ShowImpressum(value)}
                  ShowDatenschutz={(value) =>props.ShowDatenschutz(value)}
                  ShowAGB={(value) => props.ShowAGB(value)}
                  ShowContact={(value) => props.ShowContact(value)}
                  ShowAboutUs={(value) => props.ShowAboutUs(value)}
                  handleOpen={handleOpen}
                  setAppToken={props.setAppToken}
                  onLogoutDeleteItemsAndCategories={() => props.onLogoutDeleteItemsAndCategories()}/>
                </li>
                : null
              }

            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}